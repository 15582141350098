import React from 'react'

const AboutPage = () => {
	return (
		<div>
			<h1>About</h1>
			<p>Content on About Page</p>
		</div>
	)
}

export default AboutPage;