// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-change-in-motion-js": () => import("./../../../src/pages/a-change-in-motion.js" /* webpackChunkName: "component---src-pages-a-change-in-motion-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-countdown-remake-js": () => import("./../../../src/pages/countdown-remake.js" /* webpackChunkName: "component---src-pages-countdown-remake-js" */),
  "component---src-pages-drawings-js": () => import("./../../../src/pages/drawings.js" /* webpackChunkName: "component---src-pages-drawings-js" */),
  "component---src-pages-from-a-to-b-and-back-again-js": () => import("./../../../src/pages/from-a-to-b-and-back-again.js" /* webpackChunkName: "component---src-pages-from-a-to-b-and-back-again-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lab-js": () => import("./../../../src/pages/lab.js" /* webpackChunkName: "component---src-pages-lab-js" */),
  "component---src-pages-lottie-for-framer-js": () => import("./../../../src/pages/lottie-for-framer.js" /* webpackChunkName: "component---src-pages-lottie-for-framer-js" */),
  "component---src-pages-mobile-keyboard-experiences-js": () => import("./../../../src/pages/mobile-keyboard-experiences.js" /* webpackChunkName: "component---src-pages-mobile-keyboard-experiences-js" */),
  "component---src-pages-notes-js": () => import("./../../../src/pages/notes.js" /* webpackChunkName: "component---src-pages-notes-js" */),
  "component---src-pages-sequence-minigame-js": () => import("./../../../src/pages/sequence-minigame.js" /* webpackChunkName: "component---src-pages-sequence-minigame-js" */),
  "component---src-pages-sticky-headers-js": () => import("./../../../src/pages/sticky-headers.js" /* webpackChunkName: "component---src-pages-sticky-headers-js" */),
  "component---src-pages-the-ux-of-mobile-settings-js": () => import("./../../../src/pages/the-ux-of-mobile-settings.js" /* webpackChunkName: "component---src-pages-the-ux-of-mobile-settings-js" */),
  "component---src-pages-unlocking-ideas-js": () => import("./../../../src/pages/unlocking-ideas.js" /* webpackChunkName: "component---src-pages-unlocking-ideas-js" */)
}

